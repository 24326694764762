import styles from "components/input/input.module.scss";
import utilStyles from "styles/utils.module.scss";
import classNames from "classnames";
import {useEffect, forwardRef} from "react";
import {dashedPhoneNumber} from "common/utils";

import CheckCircleGreen from "images/icons/check_circle_green.svg";



export const INPUT_CLASS_NAME = 'hmk_text_input';

const TextInput = forwardRef((props, ref) => {
    const {
        onFocusOut,
        placeholder, maxLength, value, disabled, ellipsis, readonly, error, type,
        pattern, dark, multiLine, height, redCounter, allowEmptyThrottling, highlightBorder,
        trimOnCount, checked, autoComplete, right,
    } = props;
    const onChange = props.onChange || function(){};
    const onEnter = props.onEnter || function (e) {};
    const onChangeThrottled = props.onChangeThrottled || function(){};
    const onBlur = props.onBlur || function(){};

    const RightComponent = props.right;

    useEffect(() => {
        if (!value && !allowEmptyThrottling) {
            return;
        }
        const handler = setTimeout(() => {
            onChangeThrottled(value);
        }, 500);
        return () => {
            clearTimeout(handler);
        }
    }, [value]);

    const onChangeWrapper = (value) => {
        if (type === 'tel' || type === 'phone') {
            if (!/^[\d-]*$/.test(value)) {
                return;
            }

            const newValue = dashedPhoneNumber(value, type === 'phone');
            onChange(newValue);
            return;
        }
        onChange(value);
    }

    const onChangeMultiLine = (value) => {
        onChange(value);
    }

    const pureString = trimOnCount ? (value || '').trim() : (value || '');
    const length = [...pureString].length;

    return (
        multiLine ?
            <div className={classNames(
                styles.input, styles.textareaWrapper, disabled ? styles.input_disabled : undefined, readonly ? styles.input_readonly : undefined,
                error ? styles.error : undefined, dark ? styles.dark : undefined, styles.highlightBorder
                // highlightBorder ? styles.highlightBorder : undefined,
            )} style={{height: height}}>
                <textarea ref={ref} name="" id="" cols="30" rows="10" value={value} className={classNames(INPUT_CLASS_NAME, styles.textarea)} placeholder={placeholder}
                          onChange={e => { onChangeMultiLine(e.target.value) }} disabled={disabled || readonly}
                          onBlur={e => onBlur(e)} maxLength={maxLength} />
                {
                    !!maxLength &&
                    <div className={styles.maxLengthContainer}>
                        <div />
                        <div className={redCounter ? styles.redText : undefined}>
                            <span>{length}</span>
                            <span className={classNames(styles.maxLength, redCounter ? styles.redText : undefined)}>/{maxLength}</span>
                        </div>
                    </div>
                }
            </div>
            :
            <div className={classNames(utilStyles.relative, utilStyles.fullWidth)}>
                <input
                    ref={ref}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            onEnter(e);
                        }
                    }}
                    aria-invalid={false}
                    autoComplete={autoComplete}
                    inputMode={type === 'number' ? 'numeric' : undefined}
                    pattern={pattern}
                    type={type === 'phone' ? 'tel' : (type || 'text')} value={value} disabled={disabled || readonly} onChange={evt => onChangeWrapper(evt.target.value)}
                    onBlur={e => onBlur(e)}
                    className={classNames(
                        INPUT_CLASS_NAME,
                        styles.input, disabled ? styles.input_disabled : undefined, readonly ? styles.input_readonly : undefined,
                        error ? styles.error : undefined, dark ? styles.dark : undefined, styles.highlightBorder
                        // highlightBorder ? styles.highlightBorder : undefined,
                    )} placeholder={placeholder} maxLength={maxLength} style={{textOverflow: ellipsis ? 'ellipsis' : undefined}}>
                </input>
                {
                    (!!checked || !!right) &&
                    <div className={styles.right} style={{right: checked ? 16 : 12}}>
                        {
                            checked ?
                                <CheckCircleGreen />
                                :
                                <RightComponent />
                        }
                    </div>
                }
            </div>

    );
});

export default TextInput;
