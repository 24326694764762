import moment from "moment/moment";

export const leftTimestampToText = (startTime, endTime) => {
    const leftTimestamp = endTime - startTime;
    const leftDays = Math.floor(leftTimestamp / (1000 * 60 * 60 * 24));
    if (leftDays > 0) {
        return `${leftDays}일`;
    }
    const leftHours = Math.floor((leftTimestamp % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    if (leftHours > 0) {
        return `${leftHours}시간`;
    }
    const leftMinutes = Math.floor((leftTimestamp % (1000 * 60 * 60)) / (1000 * 60));
    if (leftMinutes > 0) {
        return `${leftMinutes}분`;
    }
    const leftSeconds = Math.floor((leftTimestamp % (1000 * 60)) / 1000);
    return `${leftSeconds}초`;
}

const getDayString = (days, countryCode) => {
    if (countryCode === 'KR') {
        return '일';
    }
    else if (countryCode === 'US') {
        if (days === 1) {
            return ' Day';
        }
        return ' Days';
    }
    else {
        return null;
    }
}

export const secondToVerboseText = (seconds, countryCode) => {
    if (!Boolean(countryCode)) {
        return {timeString: null, detailHour: null, detailMinute: null, detailSecond: null};
    }
    const dayLeft = seconds > 3600 * 24 ? Math.floor(seconds / (3600 * 24)) : 0;
    const dayString = dayLeft > 0 ? `${dayLeft}${getDayString(dayLeft, countryCode)} ` : '';
    const hoursString = seconds >= 1 ? moment.utc(seconds * 1000).format('HH:mm:ss') : '';
    const detailHour = seconds >= 1 ? moment.utc(seconds * 1000).format('HH') : '';
    const detailMinute = seconds >= 1 ? moment.utc(seconds * 1000).format('mm') : '';
    const detailSecond = seconds >= 1 ? moment.utc(seconds * 1000).format('ss') : '';

    const timeString = (countryCode && seconds >= 1) ? `${dayString}${hoursString}` : null;
    return {timeString, detailHour, detailMinute, detailSecond};
};

// 2024-11-12 -> 2024년 11월 12일
export const toKorDate = (date) => {
    return date.split('-').map((date, index) => {
        if (index === 0) {
            return `${parseInt(date)}년 `;
        }
        if (index === 1) {
            return `${parseInt(date)}월 `;
        }
        if (index === 2) {
            return `${parseInt(date)}일`;
        }
        return '';
    });
};
