import styles from 'components/layout/Footer/EnFooter.module.scss';
import utilStyles from 'styles/utils.module.scss';
import classNames from "classnames";

import BlackFacebookIcon from 'images/icons/facebook_black.svg';
import BlackInstagramIcon from 'images/icons/instagram_black.svg';
import BlackYoutubeIcon from 'images/icons/youtube_black.svg';
import BlackTiktokIcon from "images/icons/tiktok_black.svg";
import Link from "next/link";
import {privacyPolicyRoute, termsOfServiceRoute, refundPolicyRoute, shippingPolicyRoute} from "common/const";


export default function EnFooter(props) {
    const onLinkClick = (title) => {
        typeof mixpanel !== 'undefined' && mixpanel.track('FooterLinkClick', {title});
    };

    return (
        <div className={styles.content}>
            <div className={styles.listSection}>
                <div className={styles.bigTitles}>
                    <Link href={termsOfServiceRoute}>
                        <a target="_blank" className={classNames(styles.li, styles.bold)}
                           onClick={() => onLinkClick('이용약관')}
                        >Terms of Use</a>
                    </Link>
                    <Link href={privacyPolicyRoute}>
                        <a target="_blank" className={classNames(styles.li, styles.bold)}
                           onClick={() => onLinkClick('개인정보처리방침')}
                        >Privacy Policy</a>
                    </Link>
                    <Link href={refundPolicyRoute}>
                        <a target="_blank" className={classNames(styles.li, styles.bold)}
                           onClick={() => onLinkClick('환불정책')}
                        >Returns & Exchanges</a>
                    </Link>
                    <Link href={shippingPolicyRoute}>
                        <a target="_blank" className={classNames(styles.li, styles.bold)}
                           onClick={() => onLinkClick('배송정책')}
                        >Shipping</a>
                    </Link>
                </div>
                <div className={styles.infoContainer}>
                    <div className={styles.info}>HEMEKO Inc.</div>
                    <div className={styles.info}>CEO: Sung Gyu Lee</div>
                    <div className={styles.info}>Address: 2F, 29, Eonju-ro 174-gil, Gangnam-gu, Seoul, Korea</div>
                    <div className={styles.info}>Business Registration No.: 666-87-02551</div>
                    <div className={styles.info}>Customer Support: help@hemeko.com | +82-2-1533-0645</div>
                </div>
                <div className={styles.iconsSection}>
                    <a target="_blank" href="https://www.facebook.com/hemekoglobal/"
                       onClick={() => onLinkClick('Facebook Icon')}
                    >
                        <BlackFacebookIcon viewBox="0 0 36 36"
                                           className={classNames(utilStyles.cursorPointer, styles.icon)}/>
                    </a>
                    <a target="_blank" href="https://www.instagram.com/hemekoglobal/"
                       onClick={() => onLinkClick('Instagram Icon')}
                    >
                        <BlackInstagramIcon viewBox="0 0 36 36"
                                            className={classNames(utilStyles.cursorPointer, styles.icon)}/>
                    </a>
                    <a target="_blank" href="https://www.youtube.com/@hemekoglobal"
                       onClick={() => onLinkClick('Youtube Icon')}
                    >
                        <BlackYoutubeIcon viewBox="0 0 36 36"
                                          className={classNames(utilStyles.cursorPointer, styles.icon)}/>
                    </a>
                    <a target="_blank" href="https://www.tiktok.com/@hemekoglobal"
                       onClick={() => onLinkClick('Tiktok Icon')}
                    >
                        <BlackTiktokIcon viewBox="0 0 18 20"
                                         className={classNames(utilStyles.cursorPointer, styles.icon)}
                                         style={{width: 18, height: 20, marginRight: 0}}/>
                    </a>
                    <a href="https://www.hesulkorea.com"
                       style={{marginLeft: 10, color: "white", width: 1, height: 1, fontSize: 1,}}>헤슬</a>
                </div>
            </div>
        </div>
    )
}
