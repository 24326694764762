import {useEffect, useRef, useState} from "react";
import moment from "moment";
import {getLocalStorageCountryCode} from "common/utils";
import {getLocalizationInfoInCSR} from "common/i18n";
import {secondToVerboseText} from "common/timeConverter";


const UNIT_MAP = {
    'KR': '일',
    'US': ' Days',
}

const useTimer = (targetTimestamp) => {
    const [seconds, setSeconds] = useState(0);
    const timerRef = useRef();
    const [countryCode, setCountryCode] = useState(null);

    useEffect(() => {
        const {countryCode} = getLocalizationInfoInCSR();
        setCountryCode(countryCode);
    }, []);

    useEffect(() => {
        const seconds = targetTimestamp ? targetTimestamp - (Date.now() / 1000) : 0;
        setSeconds(seconds) ;
        if (seconds > 0) {
            timerRef.current = setInterval(() => {
                const remainSeconds = targetTimestamp ? targetTimestamp - (Date.now() / 1000) : 0;
                if (remainSeconds <= 0) {
                    setSeconds(remainSeconds);
                    clearInterval(timerRef.current);
                } else {
                    setSeconds(remainSeconds);
                }
            }, 1000);
        }

        return () => {
            if (timerRef.current) {
                clearInterval(timerRef.current);
            }
        }
    }, [targetTimestamp]);

    return {seconds, ...secondToVerboseText(seconds, countryCode)};
};

export default useTimer;
