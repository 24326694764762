import utilStyles from 'styles/utils.module.scss'
import {APPLE_CLIENT_ID, AUTH_TYPE_APPLE, SITE_URL} from "common/const";
import {v4 as uuidv4} from "uuid";
import AppleLogin from 'react-apple-login';
import {REDIRECT_URI} from "components/buttons/NaverLoginButton";
import {useRouter} from "next/router";
import authStyles from "pages/auth/index.module.scss";
import classNames from "classnames";
import SpeechBubble from "components/SpeechBubble";
import {useRef} from "react";
import AppleLogo from "images/icons/apple.svg";
import AppleCircleLogo from "images/icons/apple_circle.svg";
import useTranslation from 'next-translate/useTranslation';


const getRedirectUri = (isSignUp) => {
    let uri = `${SITE_URL}/auth/sign-in?auth_type=${AUTH_TYPE_APPLE}`;
    if (isSignUp) {
        uri += '&is_sign_up=true'
    }
    return uri;
}

const AppleLoginButton = (props) => {
    const router = useRouter();
    const {signUp, full, lastLoginType, text} = props;
    const parentDisabled = props.disabled;
    const { t } = useTranslation('components-buttons-AppleLoginButton');

    const stateRef = useRef(uuidv4());

    return (
        <div className={utilStyles.relative}>
            {
                (text || (!!full && lastLoginType === AUTH_TYPE_APPLE)) &&
                <div className={authStyles.lastLoginBubble} style={{bottom: -20}}>
                    <SpeechBubble
                        sidePadding={12} verticalPadding={6}
                        pointLength={5} borderRadius={9999}
                        top
                        backgroundColor={utilStyles.brand}
                    >
                        <span className={authStyles.lastLoginBubbleText} style={{color: 'white'}}>
                            {text ? text : t('AppleLoginButton.recentLogin')}
                        </span>
                    </SpeechBubble>
                </div>
            }
            <div className={authStyles.wideSNSLoginButtonContainer}>
                <AppleLogin
                    clientId={APPLE_CLIENT_ID}
                    scope=""
                    redirectURI={`${getRedirectUri(!!signUp)}`}
                    responseType="code id_token"
                    responseMode="fragment"
                    state={stateRef.current}
                    usePopup={false}
                    render={({onClick, disabled}) => {
                        return (
                            <a
                                onClick={(e) => {
                                    if (disabled || parentDisabled) return;
                                    window.localStorage.setItem(AUTH_TYPE_APPLE, stateRef.current);
                                    window.localStorage.setItem(REDIRECT_URI, router.query.redirect);
                                    onClick(e);
                                }}>
                                {
                                    full ?
                                        <div className={authStyles.wideSNSLoginButton} style={{backgroundColor: '#000000'}}>
                                            <div
                                                className={classNames(utilStyles.fullFlex, utilStyles.flexRow, utilStyles.justifyContentCenter, utilStyles.alignItemsCenter, utilStyles.textAlignCenter, authStyles.wideButtonText)}
                                                style={{color: 'white'}}
                                            >
                                                <div className={utilStyles.socialIcon} style={{padding: '0 7px', overflow: 'hidden'}}>
                                                    <AppleLogo viewBox="0 0 48 48" />
                                                </div>
                                                {t('AppleLoginButton.continueWithApple')}
                                            </div>
                                        </div>
                                        :
                                        <AppleCircleLogo viewBox="0 0 48 48" className={utilStyles.cursorPointer} width={48} height={48} />
                                }
                            </a>
                        );
                    }}
                />
            </div>
        </div>
    );
};

export default AppleLoginButton;
