import styles from 'components/layout/Footer/footer.module.scss';
import utilStyles from 'styles/utils.module.scss';
import BlackArrowRightIcon from 'images/icons/arrow_right_black.svg';
import classNames from "classnames";

import BlackFacebookIcon from 'images/icons/facebook_black.svg';
import BlackInstagramIcon from 'images/icons/instagram_black.svg';
import BlackYoutubeIcon from 'images/icons/youtube_black.svg';
import BlackTiktokIcon from "images/icons/tiktok_black.svg";
import GlobalMallIcon from 'images/icons/global.svg';
import Link from "next/link";
import KakaoChannelIcon from "images/icons/kakao_channel_transparent.svg";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {faqRoute, privacyPolicyRoute, termsOfServiceRoute} from "common/const";


export default function KoFooter (props) {
    const isLarge = useMediaQuery(`(min-width:${utilStyles.breakpointDesktop})`);
    const onLinkClick = (title) => {
        typeof mixpanel !== 'undefined' && mixpanel.track('FooterLinkClick', {title});
    };

    return (
        <div className={styles.content}>
            <a href="https://pf.kakao.com/_xownxob/chat" target="_blank"
               onClick={() => onLinkClick('카카오톡 문의하기')}
            >
                <div className={styles.leftContainer}>
                    <div className={styles.noLineHeight}>
                        <KakaoChannelIcon viewBox="0 0 18 18" width={isLarge ? 18 : 16} height={isLarge ? 18 : 16}
                                          className={utilStyles.verticalMiddle}/>
                        <span className={styles.customerCenter}>카카오톡 문의하기</span>
                        <BlackArrowRightIcon className={utilStyles.verticalMiddle}/>
                    </div>
                    <span className={styles.CSTime}>운영시간 : 평일 10:00 - 17:00<br/>점심시간 : 12:00 - 13:00</span>
                </div>
            </a>
            <div className={styles.listSection}>
                <div className={styles.bigTitles}>
                    <Link href={termsOfServiceRoute}>
                        <a target="_blank" className={classNames(styles.li, styles.bold)}
                           onClick={() => onLinkClick('이용약관')}
                        >이용약관</a>
                    </Link>
                    <Link href={privacyPolicyRoute}>
                        <a target="_blank" className={classNames(styles.li, styles.bold)}
                           onClick={() => onLinkClick('개인정보처리방침')}
                        >개인정보처리방침</a>
                    </Link>
                    <a href="https://global.hemeko.com" target="_blank"
                                                   className={classNames(styles.li, styles.globalMall)}
                                                   onClick={() => onLinkClick('SHOP GLOBAL')}
                    >
                            <GlobalMallIcon viewBox="0 0 16 17" width={16} height={17}/>
                            <span className={styles.globalText}>SHOP GLOBAL</span>
                        </a>
                    <a href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=6668702551&apv_perm_no=">
                        <a target="_blank" className={classNames(styles.li)}
                           onClick={() => onLinkClick('사업자정보확인')}
                        >사업자정보확인</a>
                    </a>
                    <Link href={faqRoute}>
                        <a target="_blank" className={classNames(styles.li)}
                           onClick={() => onLinkClick('FAQ')}
                        >FAQ</a>
                    </Link>
                </div>
                <div className={utilStyles.noLineHeight}>
                    <span className={styles.info}>상호명 :  주식회사 헤메코</span>
                    <span className={styles.info}>대표이사 :  이성규</span>
                    <span className={styles.info}>사업장 소재지 :  서울 강남구 언주로174길 29, 2층 헤메코</span>
                    <span className={styles.info}>고객센터 :  1533-0645</span>
                    <span className={styles.info}>사업자 등록번호 :  666-87-02551</span>
                    <span className={styles.info}>이메일 :  help@hemeko.com</span>
                    <span className={styles.info}>통신판매업신고번호 :  2022-서울강남-02255</span>
                </div>
                <div className={styles.iconsSection}>
                    <a target="_blank" href="https://www.facebook.com/hemekoglobal/"
                       onClick={() => onLinkClick('Facebook Icon')}
                    >
                        <BlackFacebookIcon viewBox="0 0 36 36"
                                           className={classNames(utilStyles.cursorPointer, styles.icon)}/>
                    </a>
                    <a target="_blank" href="https://www.instagram.com/hemekoglobal/"
                       onClick={() => onLinkClick('Instagram Icon')}
                    >
                        <BlackInstagramIcon viewBox="0 0 36 36"
                                            className={classNames(utilStyles.cursorPointer, styles.icon)}/>
                    </a>
                    <a target="_blank" href="https://www.youtube.com/@hemekoglobal"
                       onClick={() => onLinkClick('Youtube Icon')}
                    >
                        <BlackYoutubeIcon viewBox="0 0 36 36"
                                          className={classNames(utilStyles.cursorPointer, styles.icon)}/>
                    </a>
                    <a target="_blank" href="https://www.tiktok.com/@hemekoglobal"
                       onClick={() => onLinkClick('Tiktok Icon')}
                    >
                        <BlackTiktokIcon viewBox="0 0 18 20"
                                         className={classNames(utilStyles.cursorPointer, styles.icon)}
                                         style={{width: 18, height: 20, marginRight: 0}}/>
                    </a>
                    <a href="https://www.hesulkorea.com"
                       style={{marginLeft: 10, color: "white", width: 1, height: 1, fontSize: 1,}}>헤슬</a>
                </div>
            </div>
        </div>
    )
}
