import BaseOrderOptionCard, {
    ActionButton,
    AddToPouchButton,
    PurchaseConfirmButton,
    WriteReviewButton
} from "components/cards/order/BaseOrderOptionCard";
import styles from "components/cards/order/baseOrderOptionCard.module.scss";
import utilStyles from "styles/utils.module.scss";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Link from "next/link";

import {
    getExchangeOrderFirstStepRoute,
    getCancelDetailRoute,
    getCancelOrderRoute,
    getOrderDetailRoute,
    getRefundRequestRoute, getExchangeDetailRoute, KAKAO_INQUIRY_URL
} from "common/const";
import ReviewModal from "components/modal/ReviewModal";
import Modal from "components/Modal";
import {useContext, useMemo, useState} from "react";
import UserContext from "context/AuthContext";
import useTranslation from "next-translate/useTranslation";
import {useRouter} from "next/router";


const RefundOrderOptionButton = ({orderNumber, optionId}) => {
    const {t} = useTranslation('components-buttons-OrderButton');
    const onClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'OrderOptionCardRefundClick',
        );
    };
    return (
        <Link href={getRefundRequestRoute(orderNumber, optionId)}>
            <a className={styles.buttonWrapper} onClick={onClick}>
                <ActionButton>{t('refund.title')}</ActionButton>
            </a>
        </Link>
    )
}

const ExchangeOrderOptionButton = ({orderNumber, optionId}) => {
    const {t} = useTranslation('components-buttons-OrderButton');
    const onClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'OrderOptionCardExchangeClick',
        );
    };
    return (
        <Link href={getExchangeOrderFirstStepRoute(orderNumber, optionId)}>
            <a className={styles.buttonWrapper} onClick={onClick}>
                <ActionButton>{t('exchange.title')}</ActionButton>
            </a>
        </Link>
    )
}

const DeliveryCheckButton = ({orderNumber, optionId, trackingLink}) => {
    const {t} = useTranslation('components-buttons-OrderButton');
    const onClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'OrderOptionCardDeliveryCheckClick',
        );
    };

    return (
        <a className={styles.buttonWrapper} target="_blank" onClick={onClick} href={trackingLink}>
            <ActionButton>{t('deliveryCheck.title')}</ActionButton>
        </a>
    )
}

export const InquiryButton = () => {
    const onClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'OrderOptionCardInquiryButtonClick',
        );
    };
    return (
        <a className={styles.buttonWrapper} onClick={onClick} href={KAKAO_INQUIRY_URL}>
            <ActionButton>문의하기</ActionButton>
        </a>
    )
};

export const CancelOrderButton = ({orderNumber, optionId}) => {
    const {t} = useTranslation('components-buttons-OrderButton');
    const onClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'OrderOptionCardCancelOrderClick',
        );
    };
    return (
        <Link href={getCancelOrderRoute(orderNumber, optionId)}>
            <a className={styles.buttonWrapper} onClick={onClick}>
                <ActionButton>{t('cancel.title')}</ActionButton>
            </a>
        </Link>
    )
}

export const CancelDetailButton = ({orderNumber, optionId}) => {
    const {t} = useTranslation('components-buttons-OrderButton');
    const onClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'OrderOptionCardCancelDetailClick',
        );
    };
    return (
        <Link href={getCancelDetailRoute(orderNumber, optionId)}>
            <a className={styles.buttonWrapper} onClick={onClick}>
                <ActionButton>{t('cancel.detailTitle')}</ActionButton>
            </a>
        </Link>
    )
}

const GetButtons = (orderNumber, orderOption, onPurchaseConfirmSuccess, onReviewPostSuccess, isReviewModalOpen, setIsReviewModalOpen) => {
    // console.log(orderOption);
    const user = useContext(UserContext).user;
    console.log('orderOption', orderOption);
    const countryCode = orderOption.country_code;
    switch (orderOption.status) {
        case '입금대기':
        case '결제완료':
            if (orderOption.extra_data?.is_not_user_refundable) {
                return <InquiryButton />
            }
            if (countryCode === 'KR') {
                return (
                    <CancelOrderButton orderNumber={orderNumber} optionId={orderOption.order_option_id} />
                );
            }
            return null;
        case '상품준비중':
            return (
                <>
                    <ExchangeOrderOptionButton orderNumber={orderNumber} optionId={orderOption.order_option_id}/>
                    <RefundOrderOptionButton orderNumber={orderNumber} optionId={orderOption.order_option_id}/>
                    {/*{*/}
                    {/*    !!user &&*/}
                    {/*    <PurchaseConfirmButton*/}
                    {/*        pointsToGiveOnPurchaseConfirm={orderOption.points_to_give_on_purchase_confirm}*/}
                    {/*        onPurchaseConfirmSuccess={onPurchaseConfirmSuccess}*/}
                    {/*        orderNumber={orderNumber}*/}
                    {/*        optionId={orderOption.order_option_id}/>*/}
                    {/*}*/}
                </>
            );
        case '배송중':
        case '배송완료':
            return (
                <>
                    <ExchangeOrderOptionButton orderNumber={orderNumber} optionId={orderOption.order_option_id}/>
                    <RefundOrderOptionButton orderNumber={orderNumber} optionId={orderOption.order_option_id}/>
                    {
                        orderOption.delivery_tracking_link &&
                        <DeliveryCheckButton trackingLink={orderOption.delivery_tracking_link} />
                    }
                    {
                        !!user &&
                        <PurchaseConfirmButton
                            pointsToGiveOnPurchaseConfirm={orderOption.points_to_give_on_purchase_confirm}
                            onPurchaseConfirmSuccess={onPurchaseConfirmSuccess}
                            orderNumber={orderNumber}
                            optionId={orderOption.order_option_id} />
                    }
                </>
            );
        case '구매확정':
            return (
                <>
                    {
                        orderOption.delivery_tracking_link &&
                        <DeliveryCheckButton trackingLink={orderOption.delivery_tracking_link} />
                    }
                    <AddToPouchButton orderOption={orderOption}/>
                    {
                        !!user && !orderOption.has_reviewed &&
                        <WriteReviewButton onClick={() => setIsReviewModalOpen(true)} orderOption={orderOption} />
                    }
                </>
            )
        case '취소완료':
            return (
                <>
                    <CancelDetailButton orderNumber={orderNumber} optionId={orderOption.order_option_id} />
                    <AddToPouchButton orderOption={orderOption}/>
                </>
            )
        default:
            return
    }
}


export default function OrderOptionCard (props) {
    const {orderNumber, orderOption, noButton, currency} = props;
    const onPurchaseConfirmSuccess = props.onPurchaseConfirmSuccess || function () {};
    const onReviewPostSuccess = props.onReviewPostSuccess || function () {};

    const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);

    let finalStatus = orderOption.status;
    let extra = '';
    if ((orderOption.status === '상품준비중' || orderOption.status === '결제완료') && orderOption.is_pending) {
        extra = ' (발송보류)';
    }
    if ((orderOption.status === '배송중' || orderOption.status === '상품준비중' || orderOption.status === '결제완료') && orderOption.is_delayed) {
        extra = ' (배송지연)';
    }
    finalStatus += extra;

    const isSinglePermission = orderOption.review_permission_group.permissions.length === 1;
    const permissionId = useMemo(() => {
        if (isSinglePermission) {
            return orderOption.review_permission_group.permissions[0].permission_id;
        } else {
            return null;
        }
    }, [orderOption]);

    const buttonComponent = noButton ? null : GetButtons(orderNumber, orderOption, onPurchaseConfirmSuccess, onReviewPostSuccess, isReviewModalOpen, setIsReviewModalOpen);

    return (
        <BaseOrderOptionCard
            productOverrideLink={orderOption.product_override_link}
            brandName={orderOption.brand_name}
            count={orderOption.count}
            productId={orderOption.product_id}
            productName={orderOption.product_name}
            status={finalStatus}
            imageUrl={orderOption.product_image_url}
            optionName={orderOption.option_name}
            price={orderOption.unit_sell_price}
            disableLink={props.disableLink}
            currency={orderOption.currency || currency}
        >
            {
                !noButton && buttonComponent &&
                <>
                    <ReviewModal
                        isOpen={isReviewModalOpen}
                        close={() => setIsReviewModalOpen(false)}
                        imageUrl={orderOption.product_image_url}
                        orderOptionId={permissionId ? null : orderOption.order_option_id}
                        optionId={orderOption.option_id}
                        permissionId={permissionId}
                        brandName={orderOption.brand_name}
                        optionName={orderOption.option_name}
                        productName={orderOption.product_name}
                        resetVariable={isReviewModalOpen}
                        onSuccess={() => {
                            setIsReviewModalOpen(false);
                            onReviewPostSuccess();
                        }}
                    />
                    { buttonComponent }
                </>
            }
        </BaseOrderOptionCard>
    )
}
