import {createContext, useContext, useEffect, useRef, useState} from "react";
import {isAndroid, isApp, isIOS} from "common/utils";
import AppInstallModal, {LOCAL_STORAGE_KEY_APP_MODAL_CLOSE_UNTIL} from "components/modal/AppInstallModal";
import {checkIfOpenAllowed, hideForXMinutes,} from "common/modalHelper";
import {Axios} from "api";
import {captureException, captureMessage} from "@sentry/nextjs";
import {useRouter} from "next/router";
import MainCouponModal, {mainCouponModalKey} from "components/modal/MainCouponModal";
import UserContext from "context/AuthContext";

const ModalContext = createContext(undefined);

export let modalContextRef = {};

export default ModalContext;

const ModalContextWrapper = ({children}) => {
    const context = useContext(ModalContext);

    useEffect(() => {
        modalContextRef.current = context;
    }, []);

    useEffect(() => {
        modalContextRef.current = context;
    }, [context]);

    return (
        <>
            {children}
        </>
    )
}

export const ModalContextProvider = ({children}) => {
    const user = useContext(UserContext).user;
    const [modal, setModal] = useState(null);
    const currentCouponModalRef = useRef(null);
    const receivedCouponMoldIdsRef = useRef([]);
    const [appModalCloseCallback, setAppModalCloseCallback] = useState(null);
    const [isAppModalOpen, setIsAppModalOpen] = useState(false);
    const [couponModalInfo, setCouponModalInfo] = useState(null);
    const [shownModalIds, setShownModalIds] = useState([]);
    const router = useRouter();

    useEffect(async () => {
        if (!Boolean(user)) {
            receivedCouponMoldIdsRef.current = [];
            setCouponModalInfo(null);
        }
        try {
            const res = await Axios.get('v1/coupon-modals');
            if (res.status < 400) {
                receivedCouponMoldIdsRef.current = res.data.received_coupon_mold_ids;
                setCouponModalInfo({
                    modal_list: res.data.modal_list,
                    received_coupon_mold_ids: res.data.received_coupon_mold_ids
                });
            } else {
                captureMessage(JSON.stringify(res.data));
            }
        } catch (e) {
            console.log('error', e);
            captureException(e);
        }
    }, [user?.nickname]);

    const openModal = (cb) => {
        setModal(cb());
    };

    const closeModal = (logModalId = true) => {
        if (logModalId && currentCouponModalRef.current) {
            setShownModalIds(prev => {
                const currentModalId = currentCouponModalRef.current ? currentCouponModalRef.current.id : null;
                if (currentModalId) {
                    return [...prev, currentCouponModalRef.current.id];
                }
                return prev;
            });
        }
        currentCouponModalRef.current = null;
        setModal(null);
    };

    const isModalOpenable = (modal) => {
        const location = typeof window === 'undefined' ? null : window.location.pathname;

        if (modal.coupon_molds.length > 0 && modal.coupon_molds.filter(
            e => e.is_duplication_allowed || !receivedCouponMoldIdsRef.current.includes(e.id)
        ).length === 0) {
            return false;
        }

        if (modal.blacklist_coupon_mold_ids.some(mold_id => receivedCouponMoldIdsRef.current.includes(mold_id))) {
            return false;
        }

        if (!checkIfOpenAllowed(mainCouponModalKey(modal.id))) {
            return false;
        }

        if (modal.is_app_only && !isApp()) {
            return false;
        }

        if (!checkIsPathnameInWhitelist(modal.pathname_whitelist)) {
            return false;
        }

        if (location !== '/store/pouch' && shownModalIds.includes(modal.id)) {
            return false;
        }
        return true;
    }

    const openCouponModal = (modal) => {
        closeModal(false);
        openModal(() =>
            <MainCouponModal
                modalInfo={modal} close={closeModal}
            />
        );
        currentCouponModalRef.current = modal;
    };

    const openAppInstallModal = (isAutoOpen = false) => {
        if (isApp()) {
            return;
        }
        if (isAutoOpen) {
            if (checkIfOpenAllowed(LOCAL_STORAGE_KEY_APP_MODAL_CLOSE_UNTIL)) {
                setIsAppModalOpen(true);
            }
        } else {
            setIsAppModalOpen(true);
        }
    };

    const openCouponModalByIdentifier = (identifier) => {
        console.log('identifier', identifier, couponModalInfo.modal_list);
        if (identifier === 'app_install_modal') {
            openAppInstallModal();
            return;
        }

        if (!couponModalInfo || !couponModalInfo.modal_list) {
            return;
        }
        const modal = couponModalInfo.modal_list.find((e) => e.identifier.includes(identifier));
        if (modal) {
            openCouponModal(modal);
        }
    };

    const checkIsPathnameInWhitelist = (whitelist) => {
        const location = typeof window === 'undefined' ? null : window.location.pathname;
        return whitelist.includes(location) || whitelist.includes('*') || whitelist.includes(router.pathname);
    }

    useEffect(async () => {
        const location = typeof window === 'undefined' ? null : window.location.pathname;
        if (router.locale !== 'ko') {
            return;
        }

        if (!couponModalInfo) {
            return;
        }
        if (currentCouponModalRef.current) {
            if (!checkIsPathnameInWhitelist(currentCouponModalRef.current.pathname_whitelist)) {
                closeModal(false);
            }
        }
        console.log('couponModalInfo.modal_list', couponModalInfo.modal_list);
        for (const modal of couponModalInfo.modal_list) {
            if (!isModalOpenable(modal)) {
                continue;
            }

            if (modal.pouch_target_product_ids && modal.pouch_target_product_ids.length > 0) {
                continue;
            }

            if (location === '/store/pouch') {
                continue;  // pouch modals are managed in pouch.js
            }

            openCouponModal(modal)
            break;
        }
    }, [router.pathname, couponModalInfo]);

    const registerAppModalCloseCallback = (cb) => {
        setAppModalCloseCallback(cb);
    };

    const closeAppModal = () => {
        setIsAppModalOpen(false);
        hideForXMinutes(LOCAL_STORAGE_KEY_APP_MODAL_CLOSE_UNTIL, 30);
        if (appModalCloseCallback) {
            appModalCloseCallback();
        }
    };

    // decide whether to open app install modal
    useEffect(() => {
        if ((isIOS() || isAndroid()) && !isApp()) {
            if (typeof window === 'undefined') return;
            const showAppModal = new URLSearchParams(window.location.search).get('showAppInstallModal');
            if (showAppModal !== 'true') {
                return;
            }
            const goToAppDirectly = new URLSearchParams(window.location.search).get('directToApp');
            if (goToAppDirectly === 'true') {
                return;
            }

            if (checkIfOpenAllowed(LOCAL_STORAGE_KEY_APP_MODAL_CLOSE_UNTIL)) {
                setIsAppModalOpen(true);
            }
        }
    }, []);

    const contextValue = {
        openModal,
        closeModal,
        registerAppModalCloseCallback,
        currentCouponModalRef,
        openAppInstallModal,
        receivedCouponMoldIdsRef,
        couponModalInfo,
        isModalOpenable,
        openCouponModal,
        openCouponModalByIdentifier,
    };

    return (
        <ModalContext.Provider value={contextValue}>
            <ModalContextWrapper>
                {children}
                {modal}
                <AppInstallModal close={closeAppModal} isOpen={isAppModalOpen}/>
            </ModalContextWrapper>
        </ModalContext.Provider>
    )
}
