import {defaultProduct as utilStyles, isApp, isIOS} from "common/utils";
import GoogleLoginButton from "components/buttons/GoogleLoginButton";
import AppleLoginButton from "components/buttons/AppleLoginButton";
import KakaoLoginButton from "components/buttons/KakaoLoginButton";
import NaverLoginButton, {REDIRECT_URI} from "components/buttons/NaverLoginButton";
import {useRouter} from "next/router";
import {useMemo, useState} from "react";
import SpeechBubble from "components/SpeechBubble";
import useTranslation from "next-translate/useTranslation";
import styles from "components/SignInButtonComponents.module.scss";
import Modal from "components/Modal";
import FullButton from "components/buttons/FullButton";
import Link from "next/link";
import {AUTH_TYPE_APPLE, AUTH_TYPE_KAKAO, nonMemberOrderSearchRoute} from "common/const";
import authStyles from "pages/auth/index.module.scss";
import EmailButton from "components/buttons/EmailLoginButton";



const supportedLogin = {
    'en': {
        "ios": {
            "main": ['apple'],
            "others": ['google', 'email'],
        },
        "default": {
            "main": ["google"],
            "others": ['email'],
        }
    },
    'ko': {
        "ios": {
            "main": ["kakao", "apple"],
            "others": ["naver", "google", "email"],
        },
        "default": {
            "main": ["kakao"],
            "others": ["naver", "google", "email"],
        }
    }
}

export const SignInButtonComponents = (props) => {
    const {lastLoginType} = props;
    const router = useRouter();
    const locale = router.locale;
    const isIOSApp = isApp() && isIOS();
    const buttonStructure = useMemo(() => {
        return supportedLogin[locale][isIOSApp ? 'ios' : 'default'];
    }, [router?.locale, isIOSApp])
    const {t} = useTranslation('auth');

    const [isOtherLoginModalOpen, setIsOtherLoginModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const getMainButtons = () => {
        return buttonStructure.main.map((type) => {
            return getButtonByType(type);
        });
    }

    const getOtherButtons = () => {
        return buttonStructure.others.map((type) => {
            return getButtonByType(type);
        });
    }


    const getButtonByType = (type) => {
        let text = null;
        if (type === buttonStructure?.main[0] && !Boolean(lastLoginType)) {
            text = t('sign-in.bubbleText')
        }

        switch (type) {
            case 'google':
                return (
                    <GoogleLoginButton full lastLoginType={lastLoginType} disabled={isLoading} text={text}/>
                );
            case 'email':
                return (
                    <EmailButton lastLoginType={lastLoginType} disabled={isLoading} text={text}/>
                )
            case 'apple':
                return (
                    <AppleLoginButton full lastLoginType={lastLoginType} disabled={isLoading} text={text}/>
                );
            case 'kakao':
                return (
                    <KakaoLoginButton full lastLoginType={lastLoginType} disabled={isLoading} text={text}/>
                );
            case 'naver':
                return (
                    <NaverLoginButton full lastLoginType={lastLoginType} disabled={isLoading} text={text}/>
                );
            default:
                return <></>;
        }
    }

    let redirectUrl = '';
    if (typeof window !== 'undefined') {
        redirectUrl = decodeURIComponent((new URLSearchParams(window.location.search)).get('redirect') || window.localStorage.getItem(REDIRECT_URI));
    }
    const isFromPurchase = redirectUrl.includes('/checkout');

    const onNonMemberLinkClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('SignInPageNonMemberLinkClick', {isFromPurchase});
    };

    return (
        <div>
            <Modal width={'100%'} isOpen={isOtherLoginModalOpen} close={() => {
                setIsOtherLoginModalOpen(false)
            }} bottom>
                <div className={styles.otherModalContainer}>
                    {
                        getOtherButtons().map((button, index) => {
                            return (
                                <div className={styles.snsWrapperWithoutForm}>
                                    {button}
                                </div>
                            )
                        })
                    }
                </div>
            </Modal>
            <div className={styles.buttonSectionWithoutForm}>
                <div className={styles.containerWithoutForm}>
                    {
                        getMainButtons().map((button, index) => {
                            return (
                                <div className={styles.snsWrapperWithoutForm}>
                                    {button}
                                </div>
                            )
                        })
                    }
                    <div className={styles.otherLoginWithoutForm} onClick={() => setIsOtherLoginModalOpen(true)}>
                        {t('sign-in.otherLogin')}
                        {
                            !!lastLoginType  && buttonStructure?.others?.includes(lastLoginType) &&
                            <div className={authStyles.lastLoginBubble} style={{bottom: -36}}>
                                <SpeechBubble
                                    sidePadding={12} verticalPadding={6}
                                    pointLength={5} borderRadius={9999}
                                    top
                                >
                                    <span className={authStyles.lastLoginBubbleText}>
                                        {t('sign-in.lastLogin')}
                                    </span>
                                </SpeechBubble>
                            </div>
                        }
                    </div>
                    <Link href={isFromPurchase ? redirectUrl : nonMemberOrderSearchRoute}>
                        <a className={styles.goHomeButton}
                           onClick={onNonMemberLinkClick}>
                            {isFromPurchase ? t('sign-in.nonUserOrder') : t('sign-in.nonUserOrderCheck')}
                        </a>
                    </Link>
                </div>
            </div>
        </div>
    )
}
