
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { useContext, useEffect, useState } from "react";
import styles from 'pages/auth/sign-in.module.scss';
import Link from 'next/link';
import utilStyles from 'styles/utils.module.scss';
import { promptKakaoLogin } from "components/buttons/KakaoLoginButton";
import { promptNaverLogin, REDIRECT_URI } from "components/buttons/NaverLoginButton";
import { promptGoogleLogin } from "components/buttons/GoogleLoginButton";
import { AUTH_TYPE_APPLE, AUTH_TYPE_EMAIL, AUTH_TYPE_GOOGLE, AUTH_TYPE_KAKAO, AUTH_TYPE_NAVER, findEmailRoute, findPasswordRoute, getHomeRoute, signUpRoute, snsSignUpRoute } from "common/const";
import { useRouter } from "next/router";
import { Axios } from "api";
import UserContext from "context/AuthContext";
import Logo from "images/logo.svg";
import TextInput from "components/input/TextInput";
import FullButton from "components/buttons/FullButton";
import classNames from "classnames";
import { useFormik } from "formik";
import * as yup from "yup";
import InputError from "components/input/InputError";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AuthLayout from "components/layout/AuthLayout";
import { captureException } from "@sentry/nextjs";
import { customConfirm, getLocalStorageCountryCode, isApp, isIOS } from "common/utils";
import KakaoLogo from "images/icons/kakao.svg";
import NaverLogo from "images/icons/naver.svg";
import GoogleLogo from "images/icons/google.svg";
import EmailLogo from "images/icons/email_circle.svg";
import useTranslation from "next-translate/useTranslation";
import Trans from "next-translate/Trans";
import { SignInButtonComponents } from "components/SignInButtonComponents";
export const authTypeToPrompt = {
    [AUTH_TYPE_NAVER]: promptNaverLogin,
    [AUTH_TYPE_KAKAO]: promptKakaoLogin,
    [AUTH_TYPE_GOOGLE]: promptGoogleLogin
};
export const authTypeToLogo = {
    [AUTH_TYPE_NAVER]: (props) => <NaverLogo {...props} viewBox="0 0 48 48"/>,
    [AUTH_TYPE_KAKAO]: (props) => <KakaoLogo {...props} viewBox="0 0 48 48"/>,
    [AUTH_TYPE_GOOGLE]: (props) => <GoogleLogo {...props} viewBox="0 0 48 48"/>,
    [AUTH_TYPE_EMAIL]: (props) => <EmailLogo {...props} viewBox="0 0 24 24"/>
};
const SeparatorText = ({ children, barLength }) => {
    return (<div className={classNames(utilStyles.flexRow, utilStyles.justifyContentCenter, utilStyles.alignItemsCenter)}>
            <div className={styles.separator} style={{ width: barLength }}/>
            <span className={styles.separatorContent}>{children}</span>
            <div className={styles.separator} style={{ width: barLength }}/>
        </div>);
};
export const LOCAL_STORAGE_KEY_LAST_LOGIN_TYPE = 'last_login_type';
const SignIn = (props) => {
    const router = useRouter();
    const userContext = useContext(UserContext);
    const user = userContext.user;
    const [isLoading, setIsLoading] = useState(false);
    const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false);
    const { t } = useTranslation('auth');
    useEffect(() => {
        if (user) {
            const redirect = router.query.redirect;
            if (redirect) {
                router.replace(decodeURIComponent(redirect));
            }
            else {
                router.replace(getHomeRoute());
            }
        }
    }, [user]);
    const formik = useFormik({
        enableReinitialize: false,
        initialValues: {
            email: '',
            password: '',
            auto_login: true
        },
        validationSchema: yup.object({
            email: yup.string().required(t("sign-in.email.required")).email(t("sign-in.email.invalid")),
            password: yup.string().required(t("sign-in.password.required")).min(8, t("sign-in.password.min")).max(16, t("sign-in.password.max")),
            auto_login: yup.boolean().required()
        }),
        onSubmit: values => {
            const data = {
                email: values.email,
                password: values.password,
                auto_login: values.auto_login,
                auth_type: AUTH_TYPE_EMAIL
            };
            signIn(data, router.query.redirect);
        }
    });
    const { values, setFieldValue, errors, touched, handleSubmit, setFieldError, setErrors } = formik;
    const snsLogin = (auth_type, accessToken, redirect) => {
        const data = { auth_type, token: accessToken };
        console.log(data);
        signIn(data, redirect);
    };
    const isMobile = useMediaQuery(`(max-width:${utilStyles.breakpointMobile})`);
    const withForm = !!router.query.withForm;
    const [lastLoginType, setLastLoginType] = useState(null);
    const signIn = async (data, redirect) => {
        setIsLoading(true);
        try {
            const res = await Axios.post('v1/auth/sign-in/', data);
            if (res.status < 400) {
                try {
                    window.localStorage.setItem(LOCAL_STORAGE_KEY_LAST_LOGIN_TYPE, res.data.auth_type);
                }
                catch {
                }
                try {
                    typeof window !== 'undefined' && Boolean(window.karrotPixel) && window.karrotPixel.track('Login');
                }
                catch {
                }
                userContext.setUser(res.data);
                if (!res.data.nickname) {
                    router.replace({
                        pathname: snsSignUpRoute,
                        query: router.query
                    });
                }
                else if (redirect) {
                    router.replace(decodeURIComponent(redirect));
                }
                else {
                    router.replace(getHomeRoute());
                }
            }
            else if (res.status === 403) {
                setErrors({
                    email: t('sign-in.email.wrong'),
                    password: t('sign-in.password.wrong')
                });
            }
            else if (res.status === 404) {
                if (data.auth_type === AUTH_TYPE_EMAIL) {
                    setErrors({
                        email: t('sign-in.email.wrong'),
                        password: t('sign-in.password.wrong')
                    });
                }
                else {
                    snsRegister(data.auth_type, data.token, redirect);
                }
            }
            else if (res.status === 409) {
                const existingAuthType = res.data.extra.auth_type;
                if (existingAuthType === AUTH_TYPE_EMAIL) {
                    alert(t('sign-in.isEmailUser'));
                }
                else {
                    customConfirm(t('sign-in.isSnsUser', { sns: t(`common:sns.${existingAuthType}`) }), t('common:confirm'), t('common:cancel'), () => {
                        const prompt = authTypeToPrompt[existingAuthType];
                        if (prompt)
                            prompt(redirect, false);
                    }, () => {
                    });
                }
            }
            else {
                alert(t('sign-in.error'));
            }
        }
        catch (e) {
            alert(t('sign-in.error'));
            captureException(e);
        }
        finally {
            setIsLoading(false);
        }
    };
    const snsRegister = async (authType, token, redirect) => {
        const data = { auth_type: authType, token: token };
        setIsLoading(true);
        try {
            const res = await Axios.get('v1/auth/sns-sign-up/', { params: data });
            if (res.status < 400) {
                userContext.setUser(res.data);
                if (redirect) {
                    router.replace(decodeURIComponent(redirect));
                }
                else {
                    router.replace(getHomeRoute());
                }
            }
            else if (res.status === 404) {
                router.replace({
                    pathname: snsSignUpRoute,
                    query: Object.assign({}, router.query, { auth_type: authType, token: token })
                });
            }
            else if (res.status === 409) {
                const existingAuthType = res.data.extra.auth_type;
                if (existingAuthType === AUTH_TYPE_EMAIL) {
                    alert(t('sign-in.isEmailUser'));
                }
                else {
                    customConfirm(t('sign-in.isSnsUser', { sns: t(`common:sns.${existingAuthType}`) }), t('common:confirm'), t('common:cancel'), () => {
                        const prompt = authTypeToPrompt[existingAuthType];
                        if (prompt)
                            prompt(router.query.redirect, false);
                    }, () => {
                    });
                }
            }
            else {
                alert(t('sign-in.signUpError'));
            }
        }
        catch (e) {
            alert(t('sign-in.signUpError'));
            captureException(e);
        }
        finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        const lastLoginType = window.localStorage.getItem(LOCAL_STORAGE_KEY_LAST_LOGIN_TYPE);
        setLastLoginType(lastLoginType);
    }, []);
    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        let hashString = window.location.hash;
        const authType = urlSearchParams.get('auth_type');
        const isSignUp = urlSearchParams.get('is_sign_up');
        let redirect = urlSearchParams.get('redirect') || window.localStorage.getItem(REDIRECT_URI);
        if (redirect === 'undefined') {
            redirect = null;
        }
        setTimeout(() => {
            const query = {};
            console.log(authType, isSignUp);
            if (authType) {
                query['auth_type'] = authType;
            }
            if (isSignUp) {
                query['is_sign_up'] = isSignUp;
            }
            if (redirect && redirect !== 'undefined') {
                query['redirect'] = redirect;
            }
            if (authType || isSignUp) {
                router.replace({ query: query });
            }
        }, 100);
        if (hashString.startsWith('#')) {
            hashString = hashString.substring(1);
        }
        const hashParams = new URLSearchParams(hashString);
        let token = null;
        let oldState = null;
        let state = null;
        if (authType === AUTH_TYPE_NAVER) {
            token = hashParams.get('access_token');
            state = hashParams.get('state');
            oldState = window.localStorage.getItem(AUTH_TYPE_NAVER);
        }
        else if (authType === AUTH_TYPE_KAKAO) {
            token = urlSearchParams.get('code');
            state = urlSearchParams.get('state');
            oldState = window.localStorage.getItem(AUTH_TYPE_KAKAO);
        }
        else if (authType === AUTH_TYPE_GOOGLE) {
            token = hashParams.get('id_token');
            state = hashParams.get('state');
            oldState = window.localStorage.getItem(AUTH_TYPE_GOOGLE);
        }
        else if (authType === AUTH_TYPE_APPLE) {
            token = hashParams.get('id_token');
            state = hashParams.get('state');
            oldState = window.localStorage.getItem(AUTH_TYPE_APPLE);
        }
        if (state && token && oldState && state === oldState) {
            if (isSignUp) {
                snsRegister(authType, token, redirect);
            }
            else {
                snsLogin(authType, token, redirect);
            }
        }
    }, []);
    let redirectUrl = '';
    if (typeof window !== 'undefined') {
        redirectUrl = decodeURIComponent((new URLSearchParams(window.location.search)).get('redirect') || window.localStorage.getItem(REDIRECT_URI));
    }
    const isFromPurchase = redirectUrl.includes('/checkout');
    const onNonMemberLinkClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('SignInPageNonMemberLinkClick', { isFromPurchase });
    };
    const onLogoClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('SignInPageLogoClick', { isFromPurchase });
    };
    const preventGoBack = router.query.preventGoBack === 'true';
    const isIOSApp = isApp() && isIOS();
    const signupRoute = typeof window !== 'undefined' ? `${signUpRoute}${window.location.search}` : signUpRoute;
    const [isOtherLoginModalOpen, setIsOtherLoginModalOpen] = useState(false);
    const countryCode = getLocalStorageCountryCode() ?? 'KR';
    return (withForm ?
        <div className={styles.root}>
                <div className={styles.container}>
                    <Link href={getHomeRoute()}>
                        <a onClick={onLogoClick}>
                            <Logo className={styles.logo} viewBox="0 0 157 28" width={157} height={28}/>
                        </a>
                    </Link>
                    <div className={styles.inputWrapper}>
                        <TextInput onEnter={handleSubmit} readonly={isLoading} error={errors.email && touched.email} value={values.email} onChange={e => setFieldValue('email', e)} type="email" placeholder={t('sign-in.email.placeholder')}/>
                    </div>
                    <div className={styles.inputWrapper}>
                        <TextInput onEnter={handleSubmit} readonly={isLoading} error={errors.password && touched.password} value={values.password} onChange={e => setFieldValue('password', e)} type="password" placeholder={t('sign-in.password.placeholder')}/>
                    </div>
                    {(errors.email || errors.password) && touched.email &&
                <InputError message={errors.email || errors.password}/>}
                    <div className={styles.buttonWrapper}>
                        <FullButton disabled={isLoading} title={t('sign-in.button')} height={48} fontSize={18} onClick={handleSubmit}/>
                    </div>
                    <div className={classNames(utilStyles.fullWidth)}>
                        <div className={classNames(utilStyles.noLineHeight, utilStyles.flexRow, utilStyles.alignItemsCenter, utilStyles.justifyContentCenter)}>
                            {countryCode === 'KR' &&
                <Link href={findEmailRoute}>
                                    <a className={styles.find}>{t('sign-in.findEmail')}</a>
                                </Link>}
                            {countryCode === 'KR' &&
                <div className={styles.verticalSeparator}/>}
                            {countryCode === 'KR' &&
                <Link href={findPasswordRoute}>
                                    <a className={styles.find}>{t('sign-in.findPassword')}</a>
                                </Link>}
                            {countryCode === 'KR' &&
                <div className={styles.verticalSeparator}/>}
                            <Link href={signupRoute}>
                                <a className={styles.find}>{t('sign-in.emailSignUp')}</a>
                            </Link>
                        </div>
                    </div>

                </div>
            </div>
        :
            <div className={styles.rootWithoutForm}>
                <div className={styles.containerWithoutForm}>
                    {!!preventGoBack &&
                    <Link href={getHomeRoute()}>
                            <a className={styles.lookAround}>
                                {t('sign-in.lookAround')}
                            </a>
                        </Link>}
                    <div className={styles.titleWrapperWithoutForm}>
                        <div className={styles.titleWithoutForm}>
                            <Trans i18nKey="auth:sign-in.titleWithoutForm" components={[<br />, <span className={styles.titleWithoutFormBold}></span>, <br />]}/>
                        </div>
                        <div className={styles.subTitleWithoutForm}>
                            {t('auth:sign-in.subTitleWithoutForm')}
                        </div>
                    </div>
                    <SignInButtonComponents lastLoginType={lastLoginType}/>
                </div>
            </div>);
};
SignIn.getLayout = AuthLayout;
export default SignIn;

    async function __Next_Translate__getStaticProps__193af35754d__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/auth/sign-in',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__193af35754d__ as getStaticProps }
  