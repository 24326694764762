import {createContext, useContext, useEffect, useState} from "react";
import UserContext from "context/AuthContext";
import ModalContext from "context/ModalContext";
import {isApp} from "common/utils";
import {checkIfOpenAllowed, checkSessionStorageFlagIsTrue, saveSessionStorageFlagTrue} from "common/modalHelper";
import {getLocalizationInfoInCSR, KOREA_COUNTRY_CODE} from "common/i18n";
import {getCountryCodeByUserIp} from "api/localization/i18n";
import SelectCountryModal, {
    STORAGE_KEY_COUNTRY_MODAL_CLOSE_UNTIL,
    STORAGE_KEY_COUNTRY_MODAL_SKIPPED
} from "components/modal/SelectCountryModal";
import {useRouter} from "next/router";

const I18nContext = createContext(undefined);

export let i18nContextRef = {};

export default I18nContext;

const I18nContextWrapper = ({children}) => {
    const context = useContext(I18nContext);

    useEffect(() => {
        i18nContextRef.current = context;
    }, []);

    useEffect(() => {
        i18nContextRef.current = context;
    }, [context]);

    return (
        <>
            {children}
        </>
    )
}


export const I18nContextProvider = ({children}) => {
    const [countryCode, setCountryCode] = useState(null);
    const user = useContext(UserContext).user;
    const {openModal, closeModal} = useContext(ModalContext) || {};
    const {locale, query, replace} = useRouter(); // 현재 사이트 도메인이 바뀌는 걸 detect 할 방법을 찾아야 함

    const checkCountryByIpAndOpenSelectCountryModal = async () => {
        if (isApp()) {
            return;
        }

        const flag = checkSessionStorageFlagIsTrue(STORAGE_KEY_COUNTRY_MODAL_SKIPPED);
        if (flag) {
            return;
        }

        // open modal for korea site only
        if (countryCode !== KOREA_COUNTRY_CODE) {
            return;
        }

        const ipCountryCode = await getCountryCodeByUserIp();

        if (!ipCountryCode || !countryCode || countryCode === ipCountryCode) {
            return;
        }

        if (user) {
            return;
        }

        if (!checkIfOpenAllowed(STORAGE_KEY_COUNTRY_MODAL_CLOSE_UNTIL)) {
            return;
        }

        openModal(() => {
            return (
                <SelectCountryModal
                    close={closeModal}
                    currentCountryCode={countryCode}
                />
            )
        });

    };

    useEffect(() => {
        checkCountryByIpAndOpenSelectCountryModal();
    }, [countryCode]);

    const saveCurrentCountryCode = () => {
        const {countryCode} = getLocalizationInfoInCSR();
        setCountryCode(countryCode);
    };

    useEffect(() => {
        saveCurrentCountryCode();
    }, [locale]);

    const saveCountryCode = () => {
        if (query.saveCountry) {
            saveSessionStorageFlagTrue(STORAGE_KEY_COUNTRY_MODAL_SKIPPED);
            // delete query
            const newQuery = {...query};
            delete newQuery.saveCountry;
            replace({query: newQuery});
        }
    };

    useEffect(() => {
        saveCountryCode();
    }, [query]);


    const contextValue = {
        countryCode,
    };

    return (
        <I18nContext.Provider value={contextValue}>
            <I18nContextWrapper>
                {children}
            </I18nContextWrapper>
        </I18nContext.Provider>
    )
}
