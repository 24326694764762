import UsIcon from "images/icons/country/usIcon.svg"
import JpIcon from "images/icons/country/jpIcon.svg"
import KrIcon from "images/icons/country/krIcon.svg"

export const countryChoices = {
    // "Japan": {
    //     "language": "ja",
    //     "countryCode": "JP",
    //     "phoneCountryCode": "+81",
    //     "image": JpIcon,
    //     "currency": '¥'
    // },
    "Korea": {
        "language": "ko",
        "countryCode": "KR",
        "phoneCountryCode": "+82",
        "image": KrIcon,
        "currency": '₩'
    },
    "United States": {
        "language": "en",
        "countryCode": "US",
        "phoneCountryCode": "+1",
        "image": UsIcon,
        "currency": '$'
    },
}

export function getCountryByCode(code) {
    for (const [country, info] of Object.entries(countryChoices)) {
        if (info.countryCode === code) {
            return country;
        }
    }
    return null;
}

export function getPhoneCodeByCode(code) {
    for (const [country, info] of Object.entries(countryChoices)) {
        if (info.countryCode === code) {
            return info.phoneCountryCode;
        }
    }
}

export function getStateCodeFromFullName(stateName) {
    const states = {
        "Alabama": "AL",
        "Alaska": "AK",
        "Arizona": "AZ",
        "Arkansas": "AR",
        "California": "CA",
        "Colorado": "CO",
        "Connecticut": "CT",
        "Delaware": "DE",
        "Florida": "FL",
        "Georgia": "GA",
        "Hawaii": "HI",
        "Idaho": "ID",
        "Illinois": "IL",
        "Indiana": "IN",
        "Iowa": "IA",
        "Kansas": "KS",
        "Kentucky": "KY",
        "Louisiana": "LA",
        "Maine": "ME",
        "Maryland": "MD",
        "Massachusetts": "MA",
        "Michigan": "MI",
        "Minnesota": "MN",
        "Mississippi": "MS",
        "Missouri": "MO",
        "Montana": "MT",
        "Nebraska": "NE",
        "Nevada": "NV",
        "New Hampshire": "NH",
        "New Jersey": "NJ",
        "New Mexico": "NM",
        "New York": "NY",
        "North Carolina": "NC",
        "North Dakota": "ND",
        "Ohio": "OH",
        "Oklahoma": "OK",
        "Oregon": "OR",
        "Pennsylvania": "PA",
        "Rhode Island": "RI",
        "South Carolina": "SC",
        "South Dakota": "SD",
        "Tennessee": "TN",
        "Texas": "TX",
        "Utah": "UT",
        "Vermont": "VT",
        "Virginia": "VA",
        "Washington": "WA",
        "West Virginia": "WV",
        "Wisconsin": "WI",
        "Wyoming": "WY"
    };

    return states[stateName] || null;
}
