import inputStyles from "components/input/input.module.scss";
import styles from "components/input/CountryInputSelector.module.scss";
import utilStyles from 'styles/utils.module.scss'
import ArrowDownIcon from "images/icons/arrow_down_757575.svg";
import classNames from "classnames";
import React, {useRef, useState} from "react";
import {useOutsideAlerter} from "common/utils";
import {countryChoices} from "common/country_list";

export default function CountryInputSelector(props) {
    const {value, placeholder, disableOnSingleChoice} = props;
    const onChange = props.onChange || function () {
    };

    const [isExpanded, setIsExpanded] = useState(false);
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, () => {
        setIsExpanded(false);
    });
    const disable = disableOnSingleChoice;
    const country = countryChoices[value];
    return (
        <div ref={wrapperRef} className={styles.container}>
            <div
                className={classNames(
                    inputStyles.input,
                    !disable && inputStyles.dropdownInput,
                    isExpanded ? inputStyles.dropdownInputActive : undefined,
                    disable && inputStyles.input_disabled,
                )}
                onClick={() => !disable && setIsExpanded(val => !val)}
            >
                <div className={classNames(utilStyles.flexRow, utilStyles.alignItemsCenter)}>
                    <country.image width={24} height={24}/>
                    <span className={styles.currency}>{value} ({country.currency})</span>
                </div>
                <ArrowDownIcon className={styles.arrowDownIcon} viewBox="0 0 20 20" height="16" width="16"/>
            </div>
            {
                //isExpanded &&
                <div className={classNames(inputStyles.selectorContainer, utilStyles.relative)}
                     style={{display: isExpanded ? 'block' : 'none'}}>
                    {
                        !!countryChoices && Object.entries(countryChoices).map(([countryName, item], index) => (
                            <div
                                key={`${countryName}-${index}`}
                                className={inputStyles.choice}
                                onClick={(evt) => {
                                    onChange(item.countryCode);
                                    setIsExpanded(val => !val);
                                    evt.stopPropagation();
                                }}
                            >
                                <div className={classNames(utilStyles.flexRow, utilStyles.alignItemsCenter)}>
                                    <item.image width={24} height={24}/>
                                    <span className={styles.item}>{countryName} ({item.currency})</span>
                                </div>
                            </div>
                        ))
                    }
                </div>
            }
        </div>
    );
}
