import {useContext, useEffect, useMemo, useRef, useState} from "react";
import Modal from "components/Modal";
import styles from "components/modal/feedOptionModal.module.scss";
import colors from "styles/color.module.scss";
import utilStyles from "styles/utils.module.scss";
import TitleBar from "components/layout/TitleBar";
import ScrapIconActive from "images/icons/heart_fill.svg";
import ScrapIcon from "images/icons/heart_line.svg";
import ProductScrapButton from "components/buttons/ProductScrapButton";
import classNames from "classnames";
import FullButton from "components/buttons/FullButton";
import Image from "next/image";
import PipeIcon from "images/icons/pipe.svg";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import BulletText from "components/BulletText";
import {formattedPrice, numberWithComma, shuffle, useOutsideAlerter} from "common/utils";
import UserContext from "context/AuthContext";
import LoadingDot from "components/LoadingDot";
import {captureException, captureMessage} from "@sentry/nextjs";
import {Axios} from "api";
import Loading from "components/Loading";
import StarRatingSmall from "components/review/StarRatingSmall";
import ArrowRightIcon from "images/icons/arrow_right_light.svg";
import StarRatingLong from "components/review/StarRatingLong";
import Link from "next/link";
import {getCreatorProductRoute} from "common/const";
import addOptionCountsToPouch from "api/addToPouch";
import {toast} from "react-toastify";
import DirectPurchaseModal from "components/input/DirectPurchaseModal";
import {setLocalReferralInfo} from "common/referral";
import AlertIcon from "images/icons/alert.svg";
import CloseIcon from "images/icons/close.svg";
import ImageDetailContext from "context/ImageDetailContext";
import {pouchContextRef} from "context/PouchContext";

import moment from "moment-timezone";
import {redirectToLogin} from "common/redirect";
import PostListContext from "context/PostListContext";
import OptionSelectorPouchAddedModal from "components/modal/OptionSelectorPouchAddedModal";
import {modalContextRef} from "context/ModalContext";
import {useRouter} from "next/router";
import useTranslation from "next-translate/useTranslation";
import Trans from "next-translate/Trans";
import {getLocalizationInfoInCSR} from "common/i18n";


const FeedPurchaseSection = (props) => {
    const { t } = useTranslation('components-modal-FeedOptionModal');
    const {productOption, data, isLoading, referralInfo, isFailedToGetOption, seconds} = props;
    const {user, fetchUser} = useContext(UserContext);
    const pouchContext = pouchContextRef.current;
    const isOptionShortDetailLoading = isLoading;
    const optionShortDetail = data;

    const isSoldOut = useMemo(() => {
        let _soldOut = productOption?.is_sold_out || productOption?.inventory_count === 0;
        if (data?.product?.is_sold_out || data?.product?.inventory_count === 0) {
            _soldOut = true;
        }
        return _soldOut;
    }, [productOption, data]);

    const isMembersOnlyButNotLogined = useMemo(() => {
        if (optionShortDetail) {
            return optionShortDetail.is_members_only && user === null;
        }
        return false;
    }, [optionShortDetail, user]);

    const hasReachedMaxBuyCount = useMemo(() => {
        if (optionShortDetail) {
            return optionShortDetail.max_product_buy_count > 0 && optionShortDetail.max_product_buy_count <= optionShortDetail.current_product_buy_count;
        }
        return false;
    }, [optionShortDetail]);

    const isPouchForced = true;

    const purchaseButtonText = useMemo(() => {
        if (hasReachedMaxBuyCount) {
            return t('FeedPurchaseSection.unavailable');
        }
        if (isSoldOut) {
            return t('FeedPurchaseSection.soldOut');
        }
        if (isMembersOnlyButNotLogined) {
            return t('FeedPurchaseSection.membersOnly');
        }
        if (seconds > 0) {
            return moment.utc(seconds * 1000).format('HH:mm:ss');
        }
        if (isFailedToGetOption) {
            return t('FeedPurchaseSection.temporaryError');
        }
        if (!data.option_id) {
            return t('FeedPurchaseSection.unavailableProduct');
        }
        if (isPouchForced) {
            return t('FeedPurchaseSection.addToCart');
        }
        return t('FeedPurchaseSection.purchase');
    }, [hasReachedMaxBuyCount, isSoldOut, isMembersOnlyButNotLogined, seconds, isFailedToGetOption, data]);

    const fakeProduct = {
        id: optionShortDetail?.product_id,
        name: optionShortDetail?.product_name,
        thumbnail_url: optionShortDetail?.thumbnail_url,
    };

    const onAddToPouchSuccess = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('DirectPurchaseModalPouchAddedModalOpen');
        openPouchAddedModal(fakeProduct);
    };

    const onPouch = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'FeedOptionModalAddToPouchClick',
        );
        if (!productOption) return;
        if (isLoading || isSoldOut || isMembersOnlyButNotLogined || hasReachedMaxBuyCount || seconds > 0) {
            rejectCheckout();
            return;
        }
        const [referralId, referralPostId] = referralInfo;
        const options = [{
            option_id: productOption?.id,
            count: 1,
            referral_id: referralId,
            referral_post_id: referralPostId,
            price: productOption?.discounted_price
        }];
        addOptionCountsToPouch(options, !!user, () => {
            onAddToPouchSuccess();
            fetchUser();
        }, pouchContext, false, productOption.product_id, productOption.name);
    };

    const router = useRouter();

    const rejectCheckout = () => {
        if (hasReachedMaxBuyCount) {
            toast.info(t('FeedPurchaseSection.maxPurchaseReached', { count: optionShortDetail.max_product_buy_count }));
        }
        if (isSoldOut) {
            toast.info(t('FeedPurchaseSection.soldOutProduct'));
        }
        if (isMembersOnlyButNotLogined) {
            redirectToLogin(router, true);
        }
    };

    const onPurchase = (e) => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'FeedOptionModalPurchaseButtonClick',
        );
        e.preventDefault();
        if (isLoading || isSoldOut || isMembersOnlyButNotLogined || hasReachedMaxBuyCount || seconds > 0) {
            rejectCheckout();
            return;
        }
        props.setIsPurchaseModalOpen(true);
    };

    const postListContext = useContext(PostListContext);
    const {closeFeedOptionModal} = postListContext;
    const closeDirectPurchaseModal = () => {
        closeFeedOptionModal();
    };

    const [recommendedProducts, setRecommendedProducts] = useState([]);

    useEffect(() => {
        fetchRecommendedProducts();
    }, []);

    const fetchRecommendedProducts = async () => {
        try {
            const res = await Axios.get('v1/curation/pouch_product_recommendation/');
            if (res.status < 400) {
                const products = shuffle(res.data.item_list).slice(0, 10);
                setRecommendedProducts(products);
            } else {
                captureMessage(JSON.stringify(res.data));
            }
        } catch (e) {
            captureException(e);
        } finally {
        }
    };

    const openPouchAddedModal = (fakeProduct) => {
        const {openModal, closeModal} = modalContextRef.current;
        closeFeedOptionModal();
        openModal(() =>
            <OptionSelectorPouchAddedModal
                isOpened={true}
                handleClose={closeModal}
                product={fakeProduct}
                recommendedProducts={recommendedProducts}
            />
        );
    };

    const getFullButtonFontColor = () => {
        return !data.option_id ? "#9e9e9e" : null;
    }

    const getFullButtonBackgroundColor = () => {
        return !data.option_id ? '#EEEEEE' : null;
    }

    return (
        <>
            {
                props.isPurchaseModalOpen
                    ?
                    (
                        productOption && optionShortDetail &&
                        <DirectPurchaseModal
                            option={productOption} referralInfo={referralInfo}
                            close={closeDirectPurchaseModal}
                            optionShortDetail={optionShortDetail}
                            openPouchAddedModal={openPouchAddedModal}
                        />
                    )
                    :
                    <div className={styles.purchaseSectionContainer}>
                        <ProductScrapButton
                            productId={productOption?.product_id}
                            renderScrapped={() => <ScrapIconActive viewBox="0 0 24 24"
                                                                   className={styles.purchaseScrapIcon}/>}
                            renderUnscrapped={() => <ScrapIcon viewBox="0 0 24 24"
                                                               className={styles.purchaseScrapIcon}/>}
                        />
                        {
                            isPouchForced
                                ?
                                <div className={styles.purchaseButtonWrapper}>
                                    <FullButton
                                        onClick={onPouch}
                                        backgroundColor={getFullButtonBackgroundColor()}
                                        disabled={isLoading || !productOption || isOptionShortDetailLoading || !data.option_id}
                                        light={isSoldOut || isMembersOnlyButNotLogined || hasReachedMaxBuyCount || seconds > 0 || !data.option_id}
                                        title={purchaseButtonText} fontColor={getFullButtonFontColor()}
                                        height={48} fontSize={16}
                                    />
                                </div>
                                :
                                <>
                                    {
                                        !(isSoldOut || isMembersOnlyButNotLogined || seconds > 0)
                                        &&
                                        <a className={styles.purchaseButtonWrapper}>
                                            <FullButton onClick={onPouch} disabled={isLoading || !productOption}
                                                        title={t('FeedPurchaseSection.addToCart')}
                                                        height={48} fontSize={16} white/>
                                        </a>
                                    }
                                    <a className={styles.purchaseButtonWrapper}>
                                        <FullButton
                                            light={isSoldOut || isMembersOnlyButNotLogined || hasReachedMaxBuyCount || seconds > 0}
                                            onClick={onPurchase}
                                            disabled={isLoading || !productOption || isOptionShortDetailLoading}
                                            title={purchaseButtonText}
                                            height={48} fontSize={16}
                                        />
                                    </a>
                                </>
                        }
                    </div>
            }
        </>
    );
}


export const MyPriceModal = (props) => {
    const { t } = useTranslation('components-modal-FeedOptionModal');
    const close = props.close || function () {
    };
    const modalRef = useRef(null);
    useOutsideAlerter(modalRef, close);

    return (
        <div className={styles.helperAlert} onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            close();
        }} ref={modalRef}>
            <div className={styles.helperAlertText}>{t('MyPriceModal.explanation')}</div>
            <CloseIcon viewBox="0 0 20 20" className={styles.helperAlertTextCloseButton} onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                close();
            }}/>
        </div>
    )
}


const OptionInfoSection = (props) => {
    const { t } = useTranslation('components-modal-FeedOptionModal');
    const {productOption, isLoading, data, referralInfo} = props;
    const [referralId, referralPostId] = referralInfo;
    const [isAlertOpen, setIsAlertOpen] = useState(false);

    const postListContext = useContext(PostListContext);
    const {closeFeedOptionModal} = postListContext;
    const onProductClick = () => {
        if(!data.option_id) return
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'FeedOptionModalOptionInfoSectionProductClick',
            {
                productId: data.product_id,
            }
        );
        setLocalReferralInfo(data.product_id, referralId, referralPostId);
        closeFeedOptionModal();
    };

    const onAlertIconClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'FeedOptionModalOptionInfoSectionAlertIconClick'
        );
        setIsAlertOpen(true);
    };

    const onAlertCloseClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'FeedOptionModalOptionInfoSectionAlertCloseClick'
        );
        setIsAlertOpen(false);
    };

    function descriptionContent() {
        return <a className={styles.headContainer} onClick={onProductClick}>
            <div className={styles.optionImageWrapper}>
                {
                    productOption &&
                    <Image unoptimized src={productOption?.image_url} layout="fill" objectFit="contain"/>
                }
            </div>
            <div className={styles.nameContainer}>
                <span className={styles.brandName}>{productOption?.brand_name}</span>
                <span className={styles.productName}>{productOption?.product_name}</span>
                <div className={utilStyles.noLineHeight}>
                    <span className={styles.optionText}>{t('OptionInfoSection.option')}</span>
                    <PipeIcon viewBox="0 0 16 16" className={styles.pipe}/>
                    <span className={styles.optionText}>{productOption?.name}</span>
                </div>
            </div>
            {data.option_id &&
                <div className={styles.optionRightArrowWrapper}>
                    <ArrowRightIcon className={styles.optionRightArrow} viewBox="0 0 12 12" />
                </div>
            }
        </a>;
    }

    return (
        <div className={styles.optionInfoSectionContainer}>
            {
                data.product_id ?
                <Link href={getCreatorProductRoute(referralId, data.product_id)} replace={true}>
                    {descriptionContent()}
                </Link>
                :
                descriptionContent()
            }
            {
                data.option_id ?
                    <div className={styles.priceContainer}>
                        <div className={styles.priceRow}>
                            <div
                                className={classNames(styles.priceTitle, styles.lowPriceTitle, utilStyles.noLineHeight)}>
                                <span className={classNames(utilStyles.verticalMiddle, utilStyles.relative)}>
                                    {t('OptionInfoSection.myLowestPrice')}
                                </span>
                                <AlertIcon viewBox="0 0 16 16" className={styles.myLowestPriceHelp}
                                           onClick={onAlertIconClick}/>
                                {
                                    isAlertOpen &&
                                    <MyPriceModal close={onAlertCloseClick}/>
                                }
                            </div>
                            <span className={styles.deliveryFee}>
                                {t('OptionInfoSection.freeShipping')}
                            </span>
                            {
                                isLoading || data.my_price === undefined || data.my_price === null ?
                                    <span className={classNames(styles.price, styles.lowPrice)}>
                                <LoadingDot style={{marginRight: -16}}/>
                            </span>
                                    :
                                    <span
                                        className={classNames(styles.price, styles.lowPrice)}>{formattedPrice(data.my_price, data.currency)}{t(`common:currency.${data.currency}`)}</span>
                            }
                        </div>
                        <div className={styles.priceRow}>
                            <div className={styles.priceTitle}>{t('OptionInfoSection.hemekoPrice')}</div>
                            <span className={styles.deliveryFee}>
                                {t('OptionInfoSection.freeShipping')}
                            </span>
                            <span className={styles.price}>{formattedPrice(productOption?.discounted_price, data.currency)}{t(`common:currency.${data.currency}`)}</span>
                        </div>
                        {/*<div className={styles.priceRow}>*/}
                        {/*    <div className={styles.priceTitle}>정가</div>*/}
                        {/*    <span className={styles.deliveryFee}>*/}
                        {/*        2,500*/}
                        {/*    </span>*/}
                        {/*    <span className={styles.price}>{numberWithComma(productOption?.price)}원</span>*/}
                        {/*</div>*/}
                    </div>
                    :
                    <></>
            }
        </div>
    );
}


const SimpleReviewCard = (props) => {
    const { t } = useTranslation('components-modal-FeedOptionModal');
    const {review, brandName} = props;

    const imageDetailContext = useContext(ImageDetailContext);
    const openImageDetailModal = imageDetailContext && typeof imageDetailContext != 'undefined'
        ? imageDetailContext.openDetailModal : function () {
        };

    return (
        <div className={styles.reviewCardContainer}>
            <div style={{padding: '0 16px'}}>
                <div className={classNames(utilStyles.flexRow, utilStyles.alignItemsCenter)}>
                    <StarRatingLong rate={review.score} size={16} margin={2} brandStar/>
                    {
                        review.is_mall_review &&
                        <span className={styles.mallReviewCustomerName}>
                            {brandName} {t('SimpleReviewCard.customer')}
                        </span>
                    }
                </div>
                {
                    !review.is_mall_review &&
                    <span className={styles.optionName}>
                        {t('SimpleReviewCard.option', {optionName: review.option_name})}
                    </span>
                }
                <span className={styles.reviewText}>{review.content}</span>
            </div>

            {
                review.images.length > 0 &&
                <>
                    <div className={styles.reviewImageContainer}>
                        {
                            review.images.map(e => {
                                return (
                                    <div className={styles.reviewHorizontalScrollElement} key={e.id}>
                                        <div className={styles.reviewImageWrapper}
                                             onClick={() => openImageDetailModal(e.image_url)}>
                                            <Image unoptimized layout="fill" objectFit="cover" src={e.thumbnail_url}/>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        <div style={{display: 'inline-block', flex: '0 0 20px'}}/>
                    </div>
                </>
            }
        </div>
    );
}

const ReviewSection = (props) => {
    const { t } = useTranslation('components-modal-FeedOptionModal');
    const {data, referralInfo} = props;
    const [referralId, referralPostId] = referralInfo;

    const postListContext = useContext(PostListContext);
    const {closeFeedOptionModal} = postListContext;
    const {countryCode} = getLocalizationInfoInCSR()
    const onReviewClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'FeedOptionModalReviewSectionReviewClick'
        );
        closeFeedOptionModal();
    };

    return (
        <div>
            <div className={styles.reviewTitleContainer}>
                <span className={styles.sectionTitle}>{t('ReviewSection.reviews')}</span>
                <span style={{marginRight: 6}}/>
                <StarRatingSmall noParenthesis
                                 rate={countryCode === 'KR' ? data.review_score : data.review_score || data.mall_review_score} fontSize={13} size={13} brandStar/>
                <Link href={`${getCreatorProductRoute(referralId, data.product_id)}#review`}>
                    <a onClick={onReviewClick}>
                        <span className={styles.reviewCount}>
                            {t('ReviewSection.reviewCount', { count: data.review_count + data.mall_review_count })}
                        </span>
                    </a>
                </Link>
                <ArrowRightIcon className={styles.rightArrow} viewBox="0 0 12 12" opacity="100"/>
            </div>
            <div className={utilStyles.borderUnderLine}/>
            {
                data.reviews.length === 0
                    ? <div style={{height: 600}}/>
                    : data.reviews.map(review => {
                        return (
                            <SimpleReviewCard key={review.id} brandName={data.brand_name} review={review}/>
                        )
                    })
            }
        </div>
    );
}


const FeedOptionRoot = (props) => {
    const { t } = useTranslation('components-modal-FeedOptionModal');
    const {productOption, data, isLoading, close, referralInfo, isFailedToGetOption} = props;
    const isMobile = useMediaQuery(`(max-width:${utilStyles.breakpointMobile})`);

    const onBackgroundClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'FeedOptionModalPurchaseModalClose'
        );
        props.setIsPurchaseModalOpen(false);
    }

    const getFailureButtonTitle = () => {
        if (isFailedToGetOption)
            return t('FeedOptionRoot.temporaryError');
        if (!data.option_id)
            return t('FeedOptionRoot.discontinuedProduct');
        return "";
    }

    return (
        <div className={!isMobile && styles.container}>
            <div style={{position: 'relative'}}>
                {
                    props.isPurchaseModalOpen &&
                    <div className={styles.modalBackground} onClick={onBackgroundClick}/>
                }
                {
                    !isMobile &&
                    <div className={styles.title}>
                        <TitleBar title={t('FeedOptionRoot.information')} isBack={false} isClose close={close}/>
                    </div>
                }
                <OptionInfoSection
                    productOption={productOption} data={data} isLoading={isLoading} referralInfo={referralInfo}
                />
                {
                    data.option_id && <div className={utilStyles.mobileBorder}/>
                }
                {
                    (isLoading) ?
                        <Loading style={{marginTop: 60}}/>
                        :
                        (data.option_id) ?
                            <>
                                {
                                    data.comments.length > 0 &&
                                    <div className={styles.hemekomentSection}>
                                        <span className={styles.sectionTitle}>{t('FeedOptionRoot.hemekoComment')}</span>
                                        {
                                            data.comments.map((e, idx) => {
                                                return (
                                                    <div key={idx} className={styles.bulletTextWrapper}>
                                                        <BulletText fontSize={15} lineHeight={'150%'}
                                                                    fontColor={colors.typo3}>{e}</BulletText>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                }
                                <ReviewSection referralInfo={referralInfo} data={data}/>
                            </>
                            :
                            <div className={styles.optionFailureContainer}>
                                <AlertIcon viewBox="0 0 16 16" className={styles.notExistsProductOptionHelp}/>
                                <span className={styles.notExistsProductName}>{getFailureButtonTitle()}</span>
                            </div>
            }
                {
                    !isMobile &&
                    <div className={styles.footer}>
                        <FeedPurchaseSection
                            productOption={productOption} isLoading={isLoading} data={data}
                            isPurchaseModalOpen={props.isPurchaseModalOpen}
                            setIsPurchaseModalOpen={props.setIsPurchaseModalOpen}
                            isFailedToGetOption={isFailedToGetOption}
                            setIsFailedToGetOption={props.setIsFailedToGetOption}
                            referralInfo={referralInfo}
                        />
                    </div>
                }
            </div>
        </div>
);
}

export default function FeedOptionModal(props) {
    const {productOption, isOpen, postId, referralCode} = props;
    const close = props.close || function () {
    };
    const isMobile = useMediaQuery(`(max-width:${utilStyles.breakpointMobile})`);
    const [isFailedToGetOption, setIsFailedToGetOption] = useState(false);
    const [isPurchaseModalOpen, setIsPurchaseModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({});
    const [seconds, setSeconds] = useState(0);
    const timerRef = useRef();

    useEffect(async () => {
        if (!productOption) {
            return;
        }
        try {
            setIsLoading(true);
            setIsFailedToGetOption(false);
            var requestUrl = `/v1/store/product-options/${productOption.id}/short-detail`;
            requestUrl += postId ? `?post_id=${postId}` : ``;

            const res = await Axios.get(requestUrl);

            if (res.status < 400) {
                setData(res.data);
                const seconds = res.data.opens_at_timestamp ? res.data.opens_at_timestamp - (Date.now() / 1000) : 0;

                if (seconds > 0) {
                    console.log('seconds', seconds);
                    setSeconds(seconds);
                    if (timerRef.current) {
                        clearInterval(timerRef.current);
                    }
                    timerRef.current = setInterval(() => {
                        setSeconds(seconds => seconds > 0 ? seconds - 1 : 0);
                    }, 1000);
                }

            } else {
                captureMessage(JSON.stringify(res.data));
            }
        } catch (e) {
            captureException(e);
            setIsFailedToGetOption(true);
        } finally {
            setIsLoading(false);
        }
    }, [productOption?.id]);

    const referralInfo = [referralCode, postId];

    // return <Modal
    //     isOpen={isOpen}
    //     width={'100%'}
    //     // snapPoints={({maxHeight}) => [maxHeight * 0.8]}
    //     close={close}
    //     isSheet={isMobile}
    // >
    //     <div>hihi</div>
    //     {/*<FeedOptionRoot*/}
    //     {/*    productOption={productOption} data={data} isLoading={isLoading} close={close}*/}
    //     {/*    isPurchaseModalOpen={isPurchaseModalOpen} setIsPurchaseModalOpen={setIsPurchaseModalOpen}*/}
    //     {/*    referralInfo={referralInfo}*/}
    //     {/*/>*/}
    //
    // </Modal>

    return (
        <>
            <Modal
                footer={
                    <FeedPurchaseSection
                        productOption={productOption} isLoading={isLoading} data={data}
                        isPurchaseModalOpen={isPurchaseModalOpen} setIsPurchaseModalOpen={setIsPurchaseModalOpen}
                        isFailedToGetOption={isFailedToGetOption}
                        referralInfo={referralInfo} seconds={seconds}
                    />
                }
                isOpen={isOpen}
                close={close}
                isSheet={isMobile}
                width={isMobile ? '100%' : 416}
                snapPoints={({maxHeight}) => [maxHeight * 0.8]}
            >
                <FeedOptionRoot
                    productOption={productOption} data={data} isLoading={isLoading} close={close}
                    isPurchaseModalOpen={isPurchaseModalOpen} setIsPurchaseModalOpen={setIsPurchaseModalOpen}
                    isFailedToGetOption={isFailedToGetOption}
                    referralInfo={referralInfo}
                />
            </Modal>
        </>
    );
}
