import {createContext, useContext, useEffect, useMemo, useState} from "react";
import {Axios} from "api";
import {getLocalStorageCountryCode} from "common/utils";


const DeliveryContext = createContext(undefined);

export let deliveryContextRef = {};
export default DeliveryContext;

const DeliveryContextWrapper = ({children}) => {
    const context = useContext(DeliveryContext);;

    useEffect(() => {
        deliveryContextRef.current = context;
    }, [])

    useEffect(() => {
        deliveryContextRef.current = context;
    }, [context])

    return (
        <>
            {children}
        </>
    )
}

export const DeliveryContextProvider = ({children}) => {
    const [deliveryStrategy, setDeliveryStrategy] = useState(null);


    useEffect(async () => {

        const countryCode = getLocalStorageCountryCode() ?? 'KR';

        if (countryCode === 'KR') {
            return;
        }

        const res = await Axios.get('v1/delivery/strategy');

        if (res?.status < 400) {
            setDeliveryStrategy(res.data);
        }
    }, []);

    const priceSortedDeliveryFee = useMemo(() => {
        if (!deliveryStrategy) {
            return [];
        }

        return Object.entries(
            deliveryStrategy?.delivery_table
        ).sort(
            (a, b) => a[0] - b[0]
        ).map(
            ([price, fee]) => [parseInt(price), fee]
        ) || [];
    }, [deliveryStrategy]);

    const getDeliveryFee = (price) => {
        const countryCode = getLocalStorageCountryCode() ?? 'KR';
        if (!deliveryStrategy || countryCode === 'KR') {
            return 0;
        }

        for (const [priceRange, fee] of priceSortedDeliveryFee) {
            if (price <= priceRange) {
                return fee;
            }
        }
        return 0;
    }

    const contextValue = {deliveryStrategy, priceSortedDeliveryFee, getDeliveryFee}

    return (
        <DeliveryContext.Provider value={contextValue}>
            <DeliveryContextWrapper>
                {children}
            </DeliveryContextWrapper>
        </DeliveryContext.Provider>
    )
}
