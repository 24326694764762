import {Axios} from "api/index";
import {captureException, captureMessage} from "@sentry/nextjs";


export const getCountryCodeByUserIp = async () => {
    try {
        const res = await Axios.get(`v1/user-country`);
        if (res.status < 400) {
            return res.data.country_code;
        } else {
            captureMessage(JSON.stringify(res.data));
        }
    } catch (e) {
        captureException(e);
    }
    return null;
};
